import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Link, Typography } from '@material-ui/core';
import Authlayout from '../../hoc/AuthLayout/AuthLayout';
import AuthForm from '../../common/components/AuthForm/AuthForm';
import AuthFormField from '../../common/components/AuthForm/AuthFormField/AuthFormField';
import AuthFormSubmit from '../../common/components/AuthForm/AuthFormSubmit/AuthFormSubmit';
import AuthAlert from '../../common/components/AuthAlert/AuthAlert';
import AuthTextBlock from '../../common/components/AuthTextBlock/AuthTextBlock';
import AuthDialog from '../../common/components/Dialogs/AuthDialog/AuthDialog';
import { ApolloClient, gql } from '@apollo/client/core';
import { apolloConfig } from '../../settings/apollo';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { Cookies } from 'react-cookie';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../google/google.jsx'
import Facebook from '../facebook/facebook';
import ReCAPTCHA from "react-google-recaptcha";


const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  surname: yup
    .string('Enter your surname')
    .required('Surname is required'),
  username: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(6, 'Password should be of minimum 6 characters length')
    .max(20, 'Password should be of maximum, 20 characters length')
    .matches(new RegExp('^[a-zA-Z0-9!@#$%^&*)(+=._-]+$'), 'Spaces are not allowed in password field')
    .required('Password is required'),
  termsCondition: yup
    .boolean()
    .oneOf([true], 'Must accept Terms of Use and Privacy Policy'),
});

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 2
  },
  formLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    textDecoration: 'underline',
  },
  dialogMsg: {
    '& p': {
      marginBottom: 5
    },
    '& p:last-child': {
      marginBottom: 0
    }
  },
  textLink: {
    color: theme.palette.common.gamsPetroleumBlue,
  }
}));

const client = new ApolloClient(apolloConfig);
const LOGIN_WITH_LINKEDIN = gql`
    mutation loginWithLinkedin ($linkedinAccessToken: String){
        loginWithLinkedin(linkedinAccessToken: $linkedinAccessToken){
          _id,
          userRole,
          token
        }
  }
`;
const SIGNUP = gql`
  mutation signup($input:SignUpUserInput){
    signup(input:$input){
      token
    }
  }
`;
const GET_LINKEDIN_AUTHORIZATION = gql`
query getAuthorization($code: String, $redirectUri: String){
    getAuthorization(code: $code, redirectUri: $redirectUri)
  }
`;
const LOGGED_USER = gql`
  query loggedUser {
    user {
      username,
      role
    }
  }
`;
const SignUp = props => {
  const classes = useStyles();
  const referral = props.referral ? props.referral : '';
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [signup] = useMutation(SIGNUP, { client });
  const [email, setEmail] = useState('');
  const [loginWithLinkedin] = useMutation(LOGIN_WITH_LINKEDIN, { client });
  const redirectUri = window.location.protocol + '//' + window.location.host + '/linkedin/authorization';
  const reCaptchaRef = React.useRef();
  const { linkedInLogin } = useLinkedIn({
    clientId: '77ia0jbfkyzcou',
    redirectUri,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: (code) => {
      getLinkedinAuthorization({
        variables: {
          code,
          redirectUri,
        },
      })
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      username: '',
      password: '',
      termsCondition: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setEmail(values.username)
      signup({
        variables: {
          input: {
            name: values.name,
            surname: values.surname,
            email: values.username,
            reCaptcha: reCaptchaRef.current.getValue(),
            password: values.password,
            privacyPolicy: values.termsCondition,
            baseUrl: window.location.origin + '/verify-mail',
            userGroupId: referral
          }
        }
      })
        .then(({ data }) => {
          setHasError(false);
          setSuccessDialogOpen(true);
        })
        .catch(error => {
          setSuccessDialogOpen(false);
          setHasError(true);
          setErrorMsg(error.toString());
        });
    },
  });

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  function onLinkedinAuthorization(accessToken) {
    loginWithLinkedin({
      variables: {
        linkedinAccessToken: accessToken
      },
    }).then(async (res) => {
      const user = res.data.loginWithLinkedin;
      await afterLogin(
        {
          username: user._id,
          role: user.userRole
        },
        user.token)
    }).catch((error) => {
      console.log(error)
    });
  }
  const [getLinkedinAuthorization, { loading, error, data }] = useLazyQuery(GET_LINKEDIN_AUTHORIZATION, {
    client,
    fetchPolicy: "network-only",
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (data && data.getAuthorization !== null) {
    onLinkedinAuthorization(data.getAuthorization);
  }
  const afterLogin = async function ({ username, role }, token) {
    setHasError(false);
    const cookies = new Cookies();
    await cookies.set('jwt', token);
    client.writeQuery({
      query: LOGGED_USER,
      data: {
        user: {
          username,
          role,
        },
      },
    });
    window.location.assign('/')
  }
  return (
    <>
      <Authlayout>
        <Container maxWidth="md" className={classes.container}>

          {hasError ?
            <AuthAlert errorMsg={errorMsg} />
            : null}

          <AuthForm
            formHandleSubmit={formik.handleSubmit}
            formTitle={'Create your free account'}
            formSubtitle={<AuthTextBlock
              primaryText="Start your first Challenge with GAMS Sandbox Tools: 15 days to complete the Digital Marketing Essentials career path and get your skills certified with the Profile Scorecard."
              //secondaryText={<strong>Get your 15-days free trial now. </strong>}
              cta={true}
              ctaText="Already have an account?"
              ctaRoute={referral ? `/login?referral=${referral}` : "/login"}
              ctaBtnText="Log In"
            />}
          >
            <AuthFormField
              fieldType={'text'}
              fieldGrid={6}
              fieldName={'name'}
              fieldLabel={'Name'}
              fieldValue={formik.values.name}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.name && Boolean(formik.errors.name)}
              fieldHelperText={formik.touched.name && formik.errors.name}
            />
            <AuthFormField
              fieldType={'text'}
              fieldGrid={6}
              fieldName={'surname'}
              fieldLabel={'Surname'}
              fieldValue={formik.values.surname}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.surname && Boolean(formik.errors.surname)}
              fieldHelperText={formik.touched.surname && formik.errors.surname}
            />
            <AuthFormField
              fieldType={'text'}
              fieldGrid={12}
              fieldName={'username'}
              fieldLabel={'Email'}
              fieldValue={formik.values.username}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.username && Boolean(formik.errors.username)}
              fieldHelperText={formik.touched.username && formik.errors.username}
            />
            <AuthFormField
              fieldType={'password'}
              fieldGrid={12}
              fieldName={'password'}
              fieldLabel={'Password'}
              fieldValue={formik.values.password}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.password && Boolean(formik.errors.password)}
              fieldHelperText={formik.touched.password && formik.errors.password}
              extraStyle={{ marginBottom: 0 }}
            />
            <Box display="flex" justifyContent="center" mt={0} mb={1.2} width="100%">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="6Leut7oqAAAAAPIAKsU3wVl7Yzqc5oMinHNxrQYd"
              />
            </Box>
            <AuthFormField
              fieldType={'checkbox'}
              fieldGrid={12}
              fieldName={'termsCondition'}
              fieldLabel={
                <>
                  {'I accept the '}
                  <Link
                    className={classes.formLink}
                    href="https://gamsplatform.com/terms-and-conditions"
                    rel="noopener noreferrer"
                    target="_blank"
                  >Terms of Use</Link>
                  {' and '}
                  <Link
                    className={classes.formLink}
                    href="https://gamsplatform.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >Privacy Policy</Link>
                </>
              }
              fieldValue={formik.values.termsCondition}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.termsCondition && Boolean(formik.errors.termsCondition)}
              fieldHelperText={formik.touched.termsCondition && formik.errors.termsCondition}
              extraStyle={{ marginBottom: 0, textAlign: 'center' }}
            />
            <AuthFormSubmit
              preBtnText="Cancel anytime for free"
              textSubmit={'Create account'}
            />
          </AuthForm>

        </Container>

        <AuthDialog
          emailIcon={true}
          dialogClose={handleSuccessDialogClose}
          dialogOpen={successDialogOpen}
          dialogTitle={'Check your e-mail'}
          dialogMessage={
            <Box
              className={classes.dialogMsg}>
              <Typography variant="body1" component="p">We have sent an email with a confirmation link to {email}.</Typography>
              <Typography variant="body1" component="p">If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form. </Typography>
              <Typography variant="body1" component="p"> If you need assistance, please contact us at <Link
                className={classes.textLink}
                href="mailto:learn@gamsplatform.com"
                rel="noopener noreferrer">
                learn@gamsplatform.com
              </Link>
              </Typography>
            </Box>
          }
          dialogBtnRouter={true}
          dialogBtnLink={'/login'}
          dialogActions={true}
          dialogBtnText={'Back to login'}
          id="registrationConfirmDialog"
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={5}
          mt={0}
          mb={1.2}
          width="100%"
        >
          {/* LinkedIn login */}
          <img
            onClick={linkedInLogin}
            src={linkedin}
            alt="Sign in with LinkedIn"
            style={{ maxWidth: '180px', cursor: 'pointer' }}
          />

          {/* Google login */}
          <div style={{ width: '182px', cursor: 'pointer', marginTop: '5px' }}>
            <GoogleOAuthProvider clientId="918629219103-d59k7qnkieum6rejoin797p9dbk2vsvu.apps.googleusercontent.com">
              <Google />
            </GoogleOAuthProvider>
          </div>

          {/* Facebook login */}
          <div style={{ width: '180px', cursor: 'pointer', marginTop: '5px' }}>
            <Facebook />
          </div>

        </Box>
      </Authlayout>
    </>
  );
}

export default SignUp;
