import React, { useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ApolloClient } from "@apollo/client";
import { apolloConfig } from "../../settings/apollo";
import { Cookies } from "react-cookie";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Link, Box } from '@material-ui/core';
import Authlayout from '../../hoc/AuthLayout/AuthLayout';
import AuthForm from '../../common/components/AuthForm/AuthForm';
import AuthFormField from '../../common/components/AuthForm/AuthFormField/AuthFormField';
import AuthFormSubmit from '../../common/components/AuthForm/AuthFormSubmit/AuthFormSubmit';
import AuthAlert from '../../common/components/AuthAlert/AuthAlert';
import AuthTextBlock from '../../common/components/AuthTextBlock/AuthTextBlock';
import ReCAPTCHA from "react-google-recaptcha";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../google/google.jsx'
import Facebook from '../facebook/facebook';


const client = new ApolloClient(apolloConfig);

const LOGIN = gql`
  mutation login($email:String!, $password:String){
    login(email:$email, password:$password){
      token
    }
  }
`;
const LOGIN_WITH_ReCaptcha = gql`
  mutation loginWithReCaptcha($email:String!, $password:String, $reCaptcha: String){
    loginWithReCaptcha(email:$email, password:$password, reCaptcha: $reCaptcha){
      token
    }
  }
`;
const LOGIN_WITH_LINKEDIN  = gql`
    mutation loginWithLinkedin ($linkedinAccessToken: String){
        loginWithLinkedin(linkedinAccessToken: $linkedinAccessToken){
          _id,
          userRole,
          token
        }
  }
`;
const LOGGED_USER = gql`
  query loggedUser {
    user {
      username,
      role
    }
  }
`;
const GET_LINKEDIN_AUTHORIZATION = gql`
query getAuthorization($code: String, $redirectUri: String){
    getAuthorization(code: $code, redirectUri: $redirectUri)
  }
`;
const validationSchema = yup.object({
  username: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    //.min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const useStyles = makeStyles(theme => ({
  container: {
    zIndex:2,
  },
  formLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    textDecoration: 'underline',
  },
}));


const Login = (props) => {
  const redirectUri=window.location.protocol+'//'+window.location.host+'/linkedin/authorization';  
  const { linkedInLogin } = useLinkedIn({
    clientId: '77ia0jbfkyzcou',
    redirectUri,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: (code) => {
        getLinkedinAuthorization({
          variables: {
            code,
            redirectUri,
          },
        })
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const classes = useStyles();
  const referral = new URLSearchParams(props.location.search).get("referral");
  const [login] = useMutation(LOGIN, {client});
  const [loginWithReCaptcha] = useMutation(LOGIN_WITH_ReCaptcha, {client})
  const [loginWithLinkedin] = useMutation(LOGIN_WITH_LINKEDIN, {client});
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [wrongPasswordErrorCount, setWrongPasswordErrorCount] = useState(0);
  const [showReCaptcha, setShowReCaptcha] = useState(false);
  const reCaptchaRef = React.useRef();

  function onLinkedinAuthorization(accessToken){
    loginWithLinkedin({
      variables: {
        linkedinAccessToken: accessToken
    },
    }).then(async (res) => {
      const user=res.data.loginWithLinkedin;
      await afterLogin(
        {username: user._id,
        role: user.userRole}, 
        user.token)
    }).catch((error) => {
      console.log(error)
    });
 }
  const afterLogin = async function({username,role}, token){
    setHasError(false);
    const cookies = new Cookies();
    await cookies.set('jwt', token);
    client.writeQuery({
      query : LOGGED_USER,
      data: {
        user: {
          username,
          role,
        },
      },
    });
    window.location.assign('/')
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      //keepMeLoggedIn: false,
      ReCaptcha: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if(showReCaptcha){
        loginWithReCaptcha({
          variables: {
            email: values.username,
            password: values.password,
            reCaptcha: reCaptchaRef.current.getValue()
          }
        }).then(async (res) => {
          await afterLogin(values,  res.data.loginWithReCaptcha.token)
        }).catch((error) => {
        setSubmitting(false);
        setHasError(true);
        setErrorMsg(error.toString());
        reCaptchaRef.current?.reset();
        });
      }
      else{
        login({
          variables: { email: values.username, password: values.password }
        }).then(async (res) => {
          await afterLogin(values,  res.data.login.token)
        }).catch((error) => {
          setSubmitting(false);
          setHasError(true);
          setErrorMsg(error.toString());
          reCaptchaRef.current?.reset();
          if(error.toString()==='Error: Incorrect password') {
            setWrongPasswordErrorCount(wrongPasswordErrorCount+1)
          }
          if(wrongPasswordErrorCount>=2) setShowReCaptcha(true)
        });
      }
    },
  });
  const [getLinkedinAuthorization,{loading, error,data}] = useLazyQuery(GET_LINKEDIN_AUTHORIZATION, {
    client,
    fetchPolicy: "network-only",
  });
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if(data && data.getAuthorization!==null){
    onLinkedinAuthorization(data.getAuthorization);
  }
  return (
    <Authlayout>
      <Container maxWidth="md" className={classes.container}>

        { hasError ?
          <AuthAlert errorMsg={errorMsg} />
        : null }

        <AuthForm
          formHandleSubmit={formik.handleSubmit}
          formTitle={'Log In'}
          formSubtitle={<AuthTextBlock
            cta={true}
            ctaText="Need a GAMS account?"
            ctaRoute={referral ? `/register/${referral}` : "/register"}
            ctaBtnText="Create an account"
          />}
          formFooter={
            <>
              <Link component={RouterLink} className={classes.formLink} to={referral ? `/forgot-password?referral=${referral}` : "/forgot-password"}>Forgot password?</Link>
            </>
          }
        >
          <AuthFormField
            fieldType={'text'}
            fieldGrid={12}
            fieldName={'username'}
            fieldLabel={'Email'}
            fieldValue={formik.values.username.trim()}
            fieldHandleChange={formik.handleChange}
            fieldError={formik.touched.username && Boolean(formik.errors.username)}
            fieldHelperText={formik.touched.username && formik.errors.username}
          />
          <AuthFormField
            fieldType={'password'}
            fieldGrid={12}
            fieldName={'password'}
            fieldLabel={'Password'}
            fieldValue={formik.values.password}
            fieldHandleChange={formik.handleChange}
            fieldError={formik.touched.password && Boolean(formik.errors.password)}
            fieldHelperText={formik.touched.password && formik.errors.password}
          />
        {showReCaptcha ?
        <Box display="flex" justifyContent="center" mt={0} mb={1.2} width="100%">
          <ReCAPTCHA
            ref={reCaptchaRef}
            sitekey="6Leut7oqAAAAAPIAKsU3wVl7Yzqc5oMinHNxrQYd"
          />
        </Box>
        : null}
 
          <AuthFormSubmit
            textSubmit={'Log In'}
          />
          
        </AuthForm>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={5}
          mt={0}
          mb={1.2}
          width="100%"
        >
          {/* LinkedIn login */}
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt="Sign in with LinkedIn"
        style={{ maxWidth: '180px', cursor: 'pointer' }}
      />

      {/* Google login */}
      <div style={{ width: '182px', cursor: 'pointer', marginTop: '5px' }}>
        <GoogleOAuthProvider clientId="918629219103-d59k7qnkieum6rejoin797p9dbk2vsvu.apps.googleusercontent.com">
          <Google />
        </GoogleOAuthProvider>
      </div>

      {/* Facebook login */}
      <div style={{ width: '180px', cursor: 'pointer', marginTop: '5px' }}>
        <Facebook />
      </div>

        </Box>
      </Container>
    </Authlayout>
  );
}

export default Login;
